import React, {useState} from "react";
import {
    filterSearchArea,
    filterSearchBathRoom, filterSearchCategory,
    filterSearchCitizenship,
    filterSearchCity,
    filterSearchCurrency, filterSearchFurniture, filterSearchIkamet,
    filterSearchPrice,
    filterSearchPropertyType,
    filterSearchReset,
    filterSearchRoom,
    filterSearchTags,
    filterSearchYear,
    setSearch, setSearchActiveObjects
} from "../../app/ObjectSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";

const CitizenshipParams: React.FC = () => {
    const [checked, setChecked]: any = useState([]);
    const obj = useAppSelector(state => state.objects.objects);
    const searchActiveObjects = useAppSelector(state => state.objects.searchActiveObjects);
    const citizenship = obj.map(item => item.values['32'].value[0] && item.values['32'].value[0]).filter(item => item !== undefined)
    const uniqueCitizenship = [...Array.from(new Set(citizenship))];
    const ikamet = obj.map(item => item.values['31'].value[0]);
    const uniqueIkamet = [...Array.from(new Set(ikamet))];
    const dispatch = useAppDispatch();
    const handleCheckCitizenship = (event: any) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
            dispatch(setSearchActiveObjects(searchActiveObjects + 1))
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
            dispatch(setSearchActiveObjects(searchActiveObjects - 1))
        }
        setChecked(updatedList);

        dispatch(setSearch({key: 'citizenship', value: updatedList}));

        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());
        dispatch(filterSearchFurniture());
        dispatch(filterSearchIkamet());

        dispatch(filterSearchReset());
    };

    const handleCheckIkamet = (event: any) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
            dispatch(setSearchActiveObjects(searchActiveObjects + 1))
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
            dispatch(setSearchActiveObjects(searchActiveObjects - 1))
        }
        setChecked(updatedList);

        dispatch(setSearch({key: 'ikamet', value: updatedList}));

        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());
        dispatch(filterSearchFurniture());
        dispatch(filterSearchIkamet());

        dispatch(filterSearchReset());
    }
    const paramName = (value: string): string => value === '1' ? 'Да' : 'Нет'
    return (
        <div className="area-params">
            <div className="room-params-block">
                <div className="room-params-title margin-class">Гражданство:</div>
                <ul className="filter-tabs-list">
                    {uniqueCitizenship.map((item: any, index: number) => (
                        <li key={index}>
                            <input id={item + 'citizen'} value={item} type="checkbox" onChange={handleCheckCitizenship} />
                            <label htmlFor={item + 'citizen'}>
                                <span className="filter-label-title" data-search-button-name={paramName(item)}>{paramName(item)}</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="area-params-block">
                <div className="room-params-title margin-class">ВНЖ:</div>
                <ul className="filter-tabs-list">
                    {uniqueIkamet.map((item: any, index: number) => (
                        <li key={index}>
                            <input id={item + 'ikamet'} value={item} type="checkbox" onChange={handleCheckIkamet} />
                            <label htmlFor={item + 'ikamet'}>
                                <span className="filter-label-title" data-search-button-name={paramName(item)}>{paramName(item)}</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default CitizenshipParams;