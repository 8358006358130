import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { PropertyProposal, PropertyType, PropertyTypeWithGeo } from "../types";
import CitizenshipParams from "../components/search-block/CitizenshipParams";

interface ObjectsI {
    objects: PropertyType[],
    objectsSearch: PropertyType[],
    objectsGeo: PropertyTypeWithGeo[],
    proposal: PropertyProposal[],
    searchActiveObjects: number,
    search: any
}

const initialState: ObjectsI = {
    objects: [],
    objectsSearch: [],
    objectsGeo: [],
    proposal: [],
    searchActiveObjects: 0,
    search: {
        cities: [],
        category: [],
        propertyType: [],
        citizenship: [],
        room: [],
        bathRoom: [],
        tags: [],
        currency: [],
        area: {
            min: 0,
            max: 500,
        },
        year: [],
        price: {
            min: 5000,
            max: 5000000,
        },
        furniture: [],
        ikamet: [],
    }
}

export const objectSlice = createSlice({
    name: 'objects',
    initialState,
    reducers: {
        setSearchActiveObjects: (state, action: PayloadAction<number>) => {
            state.searchActiveObjects = action.payload;
        },

        filterSearchCurrency:(state) => {
            let findObj: any[] = [];

            if (state.objectsSearch.length > 0) {
                findObj.push(...state.objectsSearch
                    .filter((item: any) => state.search.currency
                        .some((searchParam: string) => item.values[70].value[0].recordTitle === searchParam)));
            }

            state.objectsSearch = findObj;
        },

        filterSearchPrice:(state) => {
            let findObj: any[] = [];

            if (state.objectsSearch.length > 0) {
                findObj.push(...state.objectsSearch.filter((item: any) => item.values[27].value > state.search.price.min && item.values[27].value < state.search.price.max));
            } else {
                findObj.push(...state.objects.filter((item: any) => item.values[27].value > state.search.price.min && item.values[27].value < state.search.price.max));
            }

            state.objectsSearch = findObj;
        },

        filterSearchCity:(state) => {
            if (state.search.cities.length > 0) {
                let findObj: any[] = [];

                if (state.objectsSearch.length > 0) {
                    findObj.push(...state.objectsSearch
                        .filter((item: any) => state.search.cities
                            .some((searchParam: string) => item.values[55].value[0].recordValues[10][0].recordTitle === searchParam)));
                }

                state.objectsSearch = findObj;
            }
        },

        filterSearchYear:(state) => {
            if (state.search.year.length > 0) {
                let findObj: any[] = [];

                if (state.objectsSearch.length > 0) {
                    findObj.push(...state.objectsSearch
                        .filter((item: any) => state.search.year
                            .some((searchParam: string) => new Date(item.values[55].value[0].recordValues[18]).getFullYear().toString() === searchParam)));
                }

                state.objectsSearch = findObj;
            }
        },

        filterSearchArea:(state) => {
            let findObj: any[] = [];

            if (state.search.area.max !== 500 || state.search.area.min !== 0) {
                if (state.objectsSearch.length > 0) {
                    findObj.push(...state.objectsSearch.filter((item: any) => item.values[39].value > state.search.area.min && item.values[39].value < state.search.area.max));
                }

                state.objectsSearch = findObj;
            }
        },

        filterSearchTags:(state) => {
            if (state.search.tags.length > 0) {
                let findObj: any[] = [];

                if (state.objectsSearch.length > 0) {
                    findObj.push(...state.objectsSearch
                        .filter((item: any) => state.search.tags
                            .some((searchParam: string) => item.values[55].value[0].recordValues[30].map((recordTitle: any) => recordTitle.recordTitle === searchParam))));
                }

                state.objectsSearch = findObj;
            }
        },

        filterSearchBathRoom:(state) => {
            if (state.search.bathRoom.length > 0) {
                let findObj: any[] = [];

                if (state.objectsSearch.length > 0) {
                    findObj.push(...state.objectsSearch
                        .filter((item: any) => state.search.bathRoom
                            .some((searchParam: string) => item.values[75].value.toString() === searchParam)));
                }

                state.objectsSearch = findObj;
            }
        },

        filterSearchRoom:(state) => {
            if (state.search.room.length > 0) {
                let findObj: any[] = [];

                if (state.objectsSearch.length > 0) {
                    findObj.push(...state.objectsSearch
                        .filter((item: any) => state.search.room
                            .some((searchParam: string) => item.values[62].value[0].recordTitle === searchParam)));
                }
                state.objectsSearch = findObj;
            }
        },

        filterSearchPropertyType:(state) => {
            if (state.search.propertyType.length > 0) {
                let findObj: any[] = [];

                if (state.objectsSearch.length > 0) {
                    findObj.push(...state.objectsSearch
                        .filter((item: any) => state.search.propertyType
                            .some((searchParam: string) => item.values[61].value[0].recordTitle === searchParam)));
                }
                state.objectsSearch = findObj;
            }
        },

        filterSearchCategory:(state) => {
            if (state.search.category.length > 0) {
                let findObj: any[] = [];

                if (state.objectsSearch.length > 0) {
                    findObj.push(...state.objectsSearch
                        .filter((item: any) => state.search.category
                            .some((searchParam: string) => item.values[57].value[0] === searchParam)));
                }

                state.objectsSearch = findObj;
            }
        },

        filterSearchCitizenship:(state) => {
            if (state.search.citizenship.length > 0) {
                let findObj: any[] = [];

                if (state.objectsSearch.length > 0) {
                    findObj.push(...state.objectsSearch
                        .filter((item: any) => state.search.citizenship
                            .some((searchParam: string) => item.values[32].value[0] === searchParam)));
                }
                state.objectsSearch = findObj;
            }
        },
        filterSearchFurniture:(state) => {
            if (state.search.furniture.length > 0) {
                let findObj: any[] = [];

                if (state.objectsSearch.length > 0) {
                    findObj.push(...state.objectsSearch
                        .filter((item: any) => state.search.furniture
                            .some((searchParam: string) => item.values[72].value[0].recordTitle === searchParam)));
                }

                state.objectsSearch = findObj;
            }
        },
        filterSearchIkamet:(state) => {
            if (state.search.ikamet.length > 0) {
                let findObj: any[] = [];

                if (state.objectsSearch.length > 0) {
                    findObj.push(...state.objectsSearch
                        .filter((item: any) => state.search.ikamet
                            .some((searchParam: string) => item.values['31'].value[0] === searchParam)));
                }

                state.objectsSearch = findObj;
            }
        },


        filterSearchReset: (state) => {
            if (state.search.cities.length === 0 &&
                state.search.category.length === 0 &&
                state.search.propertyType.length === 0 &&
                state.search.room.length === 0 &&
                (state.search.area.max === 500 && state.search.area.min === 0) &&
                state.search.year.length === 0 &&
                state.search.bathRoom.length === 0 &&
                state.search.currency.length === 0 &&
                state.search.tags.length === 0 &&
                state.search.citizenship.length === 0 &&
                state.search.furniture.length === 0 &&
                state.search.ikamet.length === 0 &&
                (state.search.price.max === 15000000 && state.search.price.min === 5000)
            ) {
                state.objectsSearch = state.objects;
            }
        },

        setSearch: (state, action: PayloadAction<any>) => {
            state.search[action.payload.key] = action.payload.value;
        },
        setProposal: (state, action: PayloadAction<PropertyProposal>) => {
            state.proposal[0] = action.payload;
        },
        setObject: (state, action: PayloadAction<PropertyType[]>) => {
            state.objects = action.payload;
            state.objectsSearch = action.payload;
        },
        setObjectSearch: (state, action: PayloadAction<PropertyType[]>) => {
            state.objectsSearch = action.payload;
        },
        setGeoToObj: (state, action: PayloadAction<PropertyType[]>) => {
            state.objectsGeo = action.payload.map((item) => {
                if (item.values['55'].value.length) {

                    let coordinates: [number, number];

                    coordinates = item.values['55'].value[0].recordValues['45'].split(',').map((geo: string) => Number(geo))
                    if (isNaN(coordinates[0])) {
                        coordinates =  item.values['55'].value[0].recordValues['45'].split('(')[1].split(')')[0].split(',').map((geo: string) => Number(geo));
                    }
                    const isNumberCoord = typeof coordinates[0] === 'number' && typeof coordinates[1] === 'number';
                    return {
                        id: item.id,
                        values: item.values,
                        geo: isNumberCoord ? coordinates : [0, 0]
                    }
                }
                return {
                    id: item.id,
                    values: item.values,
                    geo: [0, 0]
                }
            });
        }
    }
});

export const {
    setSearchActiveObjects,
    filterSearchCitizenship,
    filterSearchFurniture,
    filterSearchIkamet,
    filterSearchReset,
    filterSearchCategory,
    filterSearchPropertyType,
    filterSearchBathRoom,
    filterSearchRoom,
    filterSearchTags,
    filterSearchYear,
    filterSearchArea,
    filterSearchPrice,
    filterSearchCurrency,
    filterSearchCity,
    setObject,
    setObjectSearch,
    setGeoToObj,
    setProposal,
    setSearch
} = objectSlice.actions;

export const selectObjectState = (state: RootState) => state.setting;

export default objectSlice.reducer;