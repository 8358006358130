import React, {useEffect, useState} from "react";
import '../../assets/styles/room-params.css';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    filterSearchArea,
    filterSearchBathRoom, filterSearchCategory, filterSearchCitizenship, filterSearchCity,
    filterSearchCurrency, filterSearchFurniture, filterSearchIkamet,
    filterSearchPrice, filterSearchPropertyType, filterSearchReset,
    filterSearchRoom, filterSearchTags, filterSearchYear,
    setSearch, setSearchActiveObjects
} from "../../app/ObjectSlice";

const RoomParams: React.FC = () => {
    const [checkedBathRoom, setCheckedBathRoom]: any = useState([]);
    const [checkedRoom, setCheckedRoom]: any = useState([]);
    const [checkedMoreList, setCheckedMoreList]: any = useState([]);
    const [uniqueRoomList, setUniqueRoomList]: any = useState([]);
    const obj = useAppSelector(state => state.objects.objects);
    const searchActiveObjects = useAppSelector(state => state.objects.searchActiveObjects);
    const roomList = obj.map(item => item.values[62].value[0].recordTitle);

    const bathList = obj.map(item => item.values[75].value);
    const uniqueBathList = [...Array.from(new Set(bathList))].sort();

    const listMore: any = [...Array.from(new Set(roomList))].sort(); ;
    const dispatch = useAppDispatch();

    useEffect(() => {
        setCheckedMoreList(Math.ceil(listMore.length / 6));
    }, [])

    useEffect(() => {
        setUniqueRoomList(listMore.slice(0, 6));
    }, [])

    const handleCheckRoom = (event: any) => {
        let updatedList = [...checkedRoom];
        if (event.target.checked) {
            updatedList = [...checkedRoom, event.target.value];
            dispatch(setSearchActiveObjects(searchActiveObjects + 1))
        } else {
            updatedList.splice(checkedRoom.indexOf(event.target.value), 1);
            dispatch(setSearchActiveObjects(searchActiveObjects - 1))
        }
        setCheckedRoom(updatedList);
        dispatch(setSearch({key: 'room', value: updatedList}));
        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());
        dispatch(filterSearchFurniture());
        dispatch(filterSearchIkamet());

        dispatch(filterSearchReset());
    };

    const handleCheckBathRoom = (event: any) => {
        let updatedList = [...checkedBathRoom];
        if (event.target.checked) {
            updatedList = [...checkedBathRoom, event.target.value];
            dispatch(setSearchActiveObjects(searchActiveObjects + 1))
        } else {
            updatedList.splice(checkedBathRoom.indexOf(event.target.value), 1);
            dispatch(setSearchActiveObjects(searchActiveObjects - 1))
        }

        setCheckedBathRoom(updatedList);
        dispatch(setSearch({key: 'bathRoom', value: updatedList}));
        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());
        dispatch(filterSearchFurniture());
        dispatch(filterSearchIkamet());

        dispatch(filterSearchReset());
    };

    const handleMoreList = () => {
        if (listMore.length === uniqueRoomList.length ) return setUniqueRoomList(listMore.slice(0, 6));
        setUniqueRoomList(uniqueRoomList.concat(listMore.slice(uniqueRoomList.length, uniqueRoomList.length + 6)));
    }
    return (
        <div className="room-params">
            <div className="room-params-block">
                <div className="room-params-title margin-class">Комнаты:</div>
                <ul className="filter-tabs-list">
                    {uniqueRoomList.map((item: any, index: number) => (
                        <li key={index}>
                            <input id={item + 'room'} value={item} type="checkbox" onChange={handleCheckRoom}/>
                            <label htmlFor={item + 'room'}>
                                <span className="filter-label-title" data-search-button-name={item}>{item}</span>
                            </label>
                        </li>
                    ))}
                    <div className="tags-button-block">
                        <button className="tags-button" onClick={handleMoreList}>
                            { listMore.length === uniqueRoomList.length ? 'Скрыть' : 'Ещё' }
                        </button>
                    </div>
                </ul>
            </div>
            <div className="area-params-block">
                <div className="room-params-title margin-class">Ванные комнаты:</div>
                <ul className="filter-tabs-list">
                    {uniqueBathList.map((item: any, index: number) => (
                        <li key={index}>
                            <input id={item + 'year'} value={item} type="checkbox" onChange={handleCheckBathRoom}/>
                            <label htmlFor={item + 'year'}>
                                <span className="filter-label-title" data-search-button-name={item}>{item}</span>
                            </label>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default RoomParams;