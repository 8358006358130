import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    filterSearchArea, filterSearchBathRoom, filterSearchCategory, filterSearchCitizenship,
    filterSearchCity,
    filterSearchCurrency, filterSearchFurniture, filterSearchIkamet,
    filterSearchPrice, filterSearchPropertyType, filterSearchReset, filterSearchRoom, filterSearchTags,
    filterSearchYear,
    setSearch, setSearchActiveObjects
} from "../../app/ObjectSlice";

const Furniture: React.FC = () => {
    const [checked, setChecked]: any = useState([]);
    const obj = useAppSelector(state => state.objects.objects);
    const searchActiveObjects = useAppSelector(state => state.objects.searchActiveObjects);
    const furnitureList = obj.map(item => item.values[72].value[0].recordTitle);
    const uniqueFurniture = [...Array.from(new Set(furnitureList))];
    const dispatch = useAppDispatch();

    const handleCheck = (event: any) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
            dispatch(setSearchActiveObjects(searchActiveObjects + 1))
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
            dispatch(setSearchActiveObjects(searchActiveObjects - 1))
        }
        setChecked(updatedList);
        dispatch(setSearch({key: 'furniture', value: updatedList}));

        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());
        dispatch(filterSearchFurniture());
        dispatch(filterSearchIkamet());

        dispatch(filterSearchReset());
    }
    return (
        <div className="filter-tabs">
            <div className="filter-tabs-title">Мебель:</div>
            <ul className="filter-tabs-list">
                {uniqueFurniture.map((item: any, index: number) => (
                    <li key={index}>
                        <input id={item} value={item} type="checkbox" onChange={handleCheck} />
                        <label htmlFor={item}>
                            <span className="filter-label-title" data-search-button-name={item}>{item}</span>
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Furniture;