import React, { useState } from "react";
import "../../assets/styles/filter-tabs.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    filterSearchArea, filterSearchBathRoom, filterSearchCategory, filterSearchCitizenship,
    filterSearchCity,
    filterSearchCurrency, filterSearchFurniture, filterSearchIkamet,
    filterSearchPrice, filterSearchPropertyType,
    filterSearchReset, filterSearchRoom, filterSearchTags, filterSearchYear,
    setSearch, setSearchActiveObjects
} from "../../app/ObjectSlice";

const PropertyType: React.FC = () => {
    const [checked, setChecked]: any = useState([]);
    const obj = useAppSelector(state => state.objects.objects);
    const searchActiveObjects = useAppSelector(state => state.objects.searchActiveObjects);
    const cities = obj.map(item => item.values[55].value[0].recordValues[10][0].recordTitle);
    const uniqueCities = [...Array.from(new Set(cities))];
    const dispatch = useAppDispatch();

    const handleCheck = (event: any) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
            dispatch(setSearchActiveObjects(searchActiveObjects + 1))
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
            dispatch(setSearchActiveObjects(searchActiveObjects - 1))
        }

        setChecked(updatedList);
        dispatch(setSearch({key: 'cities', value: updatedList}));

        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());
        dispatch(filterSearchFurniture());
        dispatch(filterSearchIkamet());

        dispatch(filterSearchReset());
    };

    return (
        <div className="filter-tabs">
            <div className="filter-tabs-title filter-margin-top">Города:</div>
            <ul className="filter-tabs-list">
                {uniqueCities.map((item: any, index: number) => (
                    <li key={index}>
                        <input id={item} value={item} type="checkbox" onChange={handleCheck} />
                        <label htmlFor={item} >
                            <span className="filter-label-title" data-search-button-name={item}>{item}</span>
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default PropertyType;