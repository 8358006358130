import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    filterSearchArea,
    filterSearchBathRoom,
    filterSearchCategory, filterSearchCitizenship,
    filterSearchCity,
    filterSearchCurrency, filterSearchFurniture, filterSearchIkamet,
    filterSearchPrice,
    filterSearchPropertyType,
    filterSearchReset,
    filterSearchRoom,
    filterSearchTags,
    filterSearchYear,
    setSearch, setSearchActiveObjects
} from "../../app/ObjectSlice";

const Category:  React.FC = () => {
    const [checked, setChecked]: any = useState([]);
    const obj = useAppSelector(state => state.objects.objects);
    const searchActiveObjects = useAppSelector(state => state.objects.searchActiveObjects);
    const categories = obj.map(item => item.values[57].value[0])
    const uniqueCategories = [...Array.from(new Set(categories))];
    const dispatch = useAppDispatch();

    const handleCheck = (event: any) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
            dispatch(setSearchActiveObjects(searchActiveObjects + 1))
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1)
            dispatch(setSearchActiveObjects(searchActiveObjects - 1));
        }
        setChecked(updatedList);
        dispatch(setSearch({key: 'category', value: updatedList}));

        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());
        dispatch(filterSearchFurniture());
        dispatch(filterSearchIkamet());

        dispatch(filterSearchReset());
    };
    const categoryName = (value: string): string => value === '1' ? 'Вторичка' : 'Новостройка'

    return (
        <div className="filter-tabs">
            <div className="filter-tabs-title">Категория:</div>
            <ul className="filter-tabs-list">
                {uniqueCategories.map((item: any, index: number) => (
                    <li key={index}>
                        <input id={item} value={item} type="checkbox" onChange={handleCheck} />
                        <label htmlFor={item}>
                            <span className="filter-label-title" data-search-button-name={categoryName(item)}>{categoryName(item)}</span>
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Category;