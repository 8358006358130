import React, {useEffect, useState} from "react";
import '../../assets/styles/tags-searching.css';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    filterSearchArea,
    filterSearchBathRoom, filterSearchCategory, filterSearchCitizenship,
    filterSearchCity,
    filterSearchCurrency, filterSearchFurniture, filterSearchIkamet,
    filterSearchPrice, filterSearchPropertyType, filterSearchReset, filterSearchRoom,
    filterSearchTags, filterSearchYear,
    setSearch, setSearchActiveObjects
} from "../../app/ObjectSlice";

const TagsSearching: React.FC = () => {
    const [checked, setChecked]: any = useState([]);
    const [checkedMoreList, setCheckedMoreList]: any = useState([]);
    const [uniqueTags, setUniqueTags]: any = useState([]);
    const obj = useAppSelector(state => state.objects.objects);
    const searchActiveObjects = useAppSelector(state => state.objects.searchActiveObjects);
    let tags = obj.map(item => item.values[55].value[0].recordValues[30]);
    let tagsData: any = [];
    tags.forEach((value: any) => {
        value.forEach((item: any) => {
            return tagsData.push(item.recordTitle);
        })
    })
    const listMore: any = [...Array.from(new Set(tagsData))] ;
    const dispatch = useAppDispatch();

    useEffect(() => {
        setCheckedMoreList(Math.ceil(listMore.length / 10))
    }, [])

    useEffect(() => {
        setUniqueTags(listMore.slice(0, 10));
    }, [])

    const handleCheck = (event: any) => {
        let updatedList = [...checked]
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
            dispatch(setSearchActiveObjects(searchActiveObjects + 1))
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
            dispatch(setSearchActiveObjects(searchActiveObjects - 1))
        }
        setChecked(updatedList);
        dispatch(setSearch({key: 'tags', value: updatedList}));

        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());
        dispatch(filterSearchFurniture());
        dispatch(filterSearchIkamet());

        dispatch(filterSearchReset());
    };

    const handleMoreList = () => {
        if (listMore.length === uniqueTags.length ) return setUniqueTags(listMore.slice(0, 10));
        setUniqueTags(uniqueTags.concat(listMore.slice(uniqueTags.length, uniqueTags.length + 10)));
    }

    return (
        <div className="tags-searching">
            <div className="tags-searching-title">Поиск по тегам</div>
            <ul className="filter-tabs-list">
                {uniqueTags.map((item: any, index: number) => (
                    <li key={index}>
                        <input id={item} value={item} type="checkbox" onChange={handleCheck} />
                        <label htmlFor={item}>
                            <span className="filter-label-title" data-search-button-name={item}>{item}</span>
                        </label>
                    </li>
                ))}
                <div className="tags-button-block">
                    <button className="tags-button" onClick={handleMoreList}>
                        { listMore.length === uniqueTags.length ? 'Скрыть' : 'Показать ещё' }
                    </button>
                </div>
            </ul>

        </div>
    )
}

export default TagsSearching;