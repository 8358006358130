import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import FilterBlock from "./FilterBlock";
import ContentContainer from '../main/teasers/ContentContainer';
import {
    filterSearchArea,
    filterSearchBathRoom,
    filterSearchCategory,
    filterSearchCitizenship,
    filterSearchCity,
    filterSearchCurrency,
    filterSearchFurniture,
    filterSearchIkamet,
    filterSearchPrice,
    filterSearchPropertyType,
    filterSearchReset,
    filterSearchRoom,
    filterSearchTags,
    filterSearchYear,
    setGeoToObj, setSearchActiveObjects
} from "../../app/ObjectSlice";

import { setOpenFilter } from "../../app/SettingSlice";

const SearchInput: React.FC = () => {
    const dispatch = useAppDispatch();
    const obj = useAppSelector(state => state.objects.objectsSearch);
    const openFilter = useAppSelector(state => state.setting.openFilter);
    const searchActiveObjects = useAppSelector(state => state.objects.searchActiveObjects);

    useEffect(() => {
        dispatch(setGeoToObj(obj));
    }, [dispatch, obj]);

    function handleClick() {
        dispatch(setOpenFilter(!openFilter));
        dispatch(setSearchActiveObjects(0))
    }

    const changeSearchValue = (event: any) => {
        if (!openFilter) {
            dispatch(setOpenFilter(!openFilter));

        }

        // const searchNameValue: any = document.querySelectorAll('.filter-label-title');

        // searchNameValue.forEach((item: any) => {
        //     if (item.dataset.searchButtonName === event.target.value) {
        //         scrollByElement(item);
        //     }
        // })
    }

    const scrollByElement = (node: any) => {
        const elementPosition = node.getBoundingClientRect().top;

        const offsetPosition = elementPosition - 300;

        window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    function handleSearch() {
        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());
        dispatch(filterSearchFurniture());
        dispatch(filterSearchIkamet());

        dispatch(filterSearchReset());

        if (!openFilter) {
            dispatch(setOpenFilter(!openFilter));
        }
    }

    return (
        <div className="search-block">
            <div className="header-search-block">
                <input className="search-input" placeholder="Поиск" onClick={changeSearchValue} />
                <div className="search-buttons">
                    <button className="search-input-button" onClick={handleSearch}>Найти</button>
                    <button className="search-input-filter" onClick={handleClick}>
                        <span>{searchActiveObjects}</span>
                        {searchActiveObjects >= 2 ? 'Фильтры' : 'Фильтр' }
                    </button>
                </div>
            </div>

            {openFilter && <FilterBlock/>}
            {!openFilter && <ContentContainer/>}
        </div>

    )
}

export default SearchInput;