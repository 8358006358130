import React from "react";
import '../../assets/styles/currency-selector.css';
import {
    filterSearchArea,
    filterSearchBathRoom, filterSearchCategory, filterSearchCitizenship,
    filterSearchCity,
    filterSearchCurrency,
    filterSearchPrice,
    filterSearchPropertyType,
    filterSearchReset,
    filterSearchRoom,
    filterSearchTags,
    filterSearchYear,
    setSearch
} from '../../app/ObjectSlice';
import { useAppDispatch, useAppSelector } from "../../app/hooks";

const CurrencySelector: React.FC = () => {

    const obj = useAppSelector(state => state.objects.objects);
    const searchParams = useAppSelector(state => state.objects.search);
    const currencies = obj.map(item => item.values[70].value[0].recordTitle);
    const uniqueCurrencies = [...Array.from(new Set(currencies))];
    const dispatch = useAppDispatch();

    const onChangeCurrency = (currency: any) => {
        dispatch(setSearch({key: 'currency', value: [currency.target.value]}));

        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());

        dispatch(filterSearchReset());
    }

    return (
        <div>
            <select className="currency-selector" name="selectedCurrency" onChange={onChangeCurrency}>
                {uniqueCurrencies.map((item: any, index: number) => {
                    return <option value={item} key={index}>{item}</option>
                })}
            </select>
        </div>
    )
}

export default CurrencySelector;
