import React, {ChangeEvent} from "react";
import "../../assets/styles/filter-block.css";
import CurrencySelector from "./CurrencySelector";
import InputPriceRange from "./InputPriceRange";
import PropertyType from "./PropertyType";
import Cities from "./Cities";
import Category from "./Category";
import TagsSearching from "./TagsSearching";
import AreaParams from "./AreaParams";
import RoomParams from "./RoomParams";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    filterSearchArea,
    filterSearchBathRoom, filterSearchCategory, filterSearchCitizenship,
    filterSearchCity,
    filterSearchCurrency, filterSearchFurniture, filterSearchIkamet,
    filterSearchPrice,
    filterSearchPropertyType,
    filterSearchReset,
    filterSearchRoom,
    filterSearchTags,
    filterSearchYear,
    setSearch
} from "../../app/ObjectSlice";
import CitizenshipParams from "./CitizenshipParams";
import Furniture from "./Furniture";
import { setOpenFilter } from "../../app/SettingSlice";

const FilterBlock: React.FC = () => {

    const price = useAppSelector(state => state.objects.search.price);
    const obj = useAppSelector(state => state.objects.objectsSearch);
    const openFilter = useAppSelector(state => state.setting.openFilter);
    const dispatch = useAppDispatch();


    const findObject = () => {
        dispatch(filterSearchCurrency());
        dispatch(filterSearchPrice());
        dispatch(filterSearchCity());
        dispatch(filterSearchYear());
        dispatch(filterSearchArea());
        dispatch(filterSearchTags());
        dispatch(filterSearchBathRoom());
        dispatch(filterSearchRoom());
        dispatch(filterSearchPropertyType());
        dispatch(filterSearchCategory());
        dispatch(filterSearchCitizenship());
        dispatch(filterSearchFurniture());
        dispatch(filterSearchIkamet());

        dispatch(filterSearchReset());

        dispatch(setOpenFilter(!openFilter));
    }

    const checkMinPrice = (minPrice: any) => {
        if (Number(minPrice) > price.max) {
            return {
                min: price.min,
                max: price.max
            }
        }

        return Number(minPrice) < 5000 ? {min: 5000, max: price.max} : {min: Number(minPrice), max: price.max}
    }

    const checkMaxPrice = (maxPrice: any) => {
        if (Number(maxPrice) < price.min) {
            return {
                min: price.min,
                max: price.max
            }
        }

        return Number(maxPrice) > 5000000 ? {max: 5000000, min: price.min} : {max: Number(maxPrice), min: price.min}
    }

    return (
        <div className="filter-block">
            <div className="filter-block-price">
                <Cities/>
                <div className="price-title">Цена</div>
                <div className="filter-input-block">
                    <div className="filter-inputs">
                        <input className="filter-input left-input"
                               value={price.min.toLocaleString()}
                               placeholder="От"
                               onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                   const minPrice = event.target.value.replace(/\s/g, '');

                                   dispatch(setSearch({key: 'price', value: checkMinPrice(minPrice)}));

                                   dispatch(filterSearchCurrency());
                                   dispatch(filterSearchPrice());
                                   dispatch(filterSearchCity());
                                   dispatch(filterSearchYear());
                                   dispatch(filterSearchArea());
                                   dispatch(filterSearchTags());
                                   dispatch(filterSearchBathRoom());
                                   dispatch(filterSearchRoom());
                                   dispatch(filterSearchPropertyType());
                                   dispatch(filterSearchCategory());
                                   dispatch(filterSearchCitizenship());
                                   dispatch(filterSearchFurniture());
                                   dispatch(filterSearchIkamet());

                                   dispatch(filterSearchReset());
                               }}
                        />
                        <input className="filter-input right-input"
                               value={price.max.toLocaleString()}
                               placeholder="До"
                               onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                   const maxPrice = event.target.value.replace(/\s/g, '');

                                   dispatch(setSearch({key: 'price', value: checkMaxPrice(maxPrice)}));

                                   dispatch(filterSearchCurrency());
                                   dispatch(filterSearchPrice());
                                   dispatch(filterSearchCity());
                                   dispatch(filterSearchYear());
                                   dispatch(filterSearchArea());
                                   dispatch(filterSearchTags());
                                   dispatch(filterSearchBathRoom());
                                   dispatch(filterSearchRoom());
                                   dispatch(filterSearchPropertyType());
                                   dispatch(filterSearchCategory());
                                   dispatch(filterSearchCitizenship());
                                   dispatch(filterSearchFurniture());
                                   dispatch(filterSearchIkamet());

                                   dispatch(filterSearchReset());
                               }}
                        />
                    </div>
                    <CurrencySelector/>
                </div>
                <div className="price-range">
                    <InputPriceRange
                        min={5000}
                        max={5000000}
                        onChange={({min, max}: { min: number; max: number }) =>
                            `min = ${Number(min)}, max = ${Number(max)}`
                        }
                    />
                </div>
                <Category/>
                <PropertyType/>
                <CitizenshipParams/>
                <RoomParams/>
                <Furniture/>
                <AreaParams
                    min={0}
                    max={500}
                    onChange={({min, max}: { min: number; max: number }) =>
                        `min = ${Number(min)}, max = ${Number(max)}`
                    }
                />
                <div>
                    <TagsSearching/>
                    <div className="object-count">
                        <button className="object-count-button" onClick={findObject}>{obj.length} объектов найдено</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterBlock;