import { FC } from "react";
import "../../assets/styles/input-price-range.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    filterSearchArea,
    filterSearchBathRoom, filterSearchCategory, filterSearchCitizenship,
    filterSearchCity,
    filterSearchCurrency, filterSearchFurniture, filterSearchIkamet,
    filterSearchPrice,
    filterSearchPropertyType,
    filterSearchReset,
    filterSearchRoom,
    filterSearchTags,
    filterSearchYear,
    setSearch
} from "../../app/ObjectSlice";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'
interface InputPriceRangeProps {
    min: number;
    max: number;
    onChange: Function;
}

const InputPriceRange: FC<InputPriceRangeProps> = ({
         min,
         max,
         onChange
     }) => {

    const dispatch = useAppDispatch();
    const currentCurrency = useAppSelector(state => state.objects.search.currency);
    const price = useAppSelector(state => state.objects.search.price);

    return (
        <div className="container-range">
            <InputRange
                maxValue={Number(max)}
                minValue={Number(min)}
                value={price}
                formatLabel={value => `${value.toLocaleString()} ${currentCurrency}`}
                onChange={(value: any) => {
                    dispatch(setSearch({key: 'price', value: value}));

                    dispatch(filterSearchCurrency());
                    dispatch(filterSearchPrice());
                    dispatch(filterSearchCity());
                    dispatch(filterSearchYear());
                    dispatch(filterSearchArea());
                    dispatch(filterSearchTags());
                    dispatch(filterSearchBathRoom());
                    dispatch(filterSearchRoom());
                    dispatch(filterSearchPropertyType());
                    dispatch(filterSearchCategory());
                    dispatch(filterSearchCitizenship());
                    dispatch(filterSearchFurniture());
                    dispatch(filterSearchIkamet());

                    dispatch(filterSearchReset());
                }} />
        </div>
    );
};

export default InputPriceRange;